.dashboard {
  display: flex;
  min-height: 100vh;
}

.menu {
  /* flex: 1; */
  background-color: #f1f1fb;
  padding-block: 4rem;
  position: relative;
  padding-inline: 1.3rem;
  position: fixed;
  height: 100%;
  z-index: 3;
}

.feed {
  flex: 5;
  padding: 4rem;
  /* padding-left: 200px; */
}

.profile {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 3.5rem;
  /* padding-inline: 1rem; */
  margin-inline: auto;
}
.profile p:nth-child(2) {
  font-weight: 300;
  font-size: 14px;
  color: #747399;
}
.profile p:nth-child(1) {
  font-weight: bold;
  /* font-size: 14px; */
  color: #393a62;
}

.menu_item {
  display: flex;
  align-items: center;
  color: #747399;
  gap: 0.7rem;
  /* padding: 0.5rem 1rem; */
  margin-bottom: 4px;
  font-weight: 400;
  transition: 0.2s ease-in-out;
  position: relative;
}
.menu_item:hover,
.active,
.active::before {
  opacity: 1 !important;
  color: #393a62;
}
.menu_item:last-child {
  position: absolute;
  bottom: 3rem;
  cursor: pointer;
  width: 100%;
}

.menu_item::before {
  position: absolute;
  opacity: 0;
  left: 0;
  content: '';
  display: block;
  width: 0.3rem;
  height: 100%;
  border-radius: 250px;
  background-color: #ea4c3c;
  transition: 0.2s ease-in-out;
}

.menu_item:hover::before {
  opacity: 1;
}

.collabs h1 {
  color: #393a62;
  margin-bottom: 1rem;
}

.editBtn {
  color: #393a62;
}

.collabs input,
.collabs select,
.collabs option,
input,
select {
  height: 35px;
  padding: 0.5rem;
  color: #393a62;
  outline: none;
  border: 2px solid #f1f1fb;
  border-radius: 5px;
  background-color: white;
  flex: 1;
  width: 314px;
}

.collabs input:focus,
select:focus,
input:focus {
  border-color: #9292ae;
}
.input label {
  color: #747399;
  font-weight: bold;
}
.input {
  display: flex;
  flex-direction: column;
}

.input_container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.collabs__container {
  display: flex;
  /* justify-content: center; */
}

.home__container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.home__container p {
  color: #747399;
}

.box {
  text-align: center;
  margin-bottom: 1rem;
}

.noadmin_container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

form h4 {
  text-align: center;
  color: #393a62;
}

.forgot_input {
  width: 100%;
}

.MuiDrawer-paper {
  background-color: #f1f1fb !important;
  border-right: none !important;
}

@media (max-width: 950px) {
  .input_container,
  .home__container {
    flex-direction: column;
  }
  .home__container {
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  .home__container .input_container {
    margin-bottom: 0;
  }
  .menu_item:last-child {
    position: relative;
    bottom: inherit;
  }
}
