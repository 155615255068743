* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
}

body {
    font-family: "Poppins", sans-serif !important;
}

.nav-link.active button {
    background: rgba(238, 238, 238, 0.347);
}

.img-container {
    text-align: center;
    margin-bottom: 2rem;
}

.img-container:hover .overlay {
    opacity: 1;
}
.overlay {
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease;
    border-radius: 50%;
    transform: scale(0.9);
}

.overlay > * {
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    
}
