main {
  height: 100vh;
  width: 100%;
  background-color: #F1F1FB;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23747399' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23393A62'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  display: grid;
  place-content: center;
}
main .container {
  display: flex;
  max-width: 80vw;
  flex-wrap: wrap;
  width: 800px;
  background-color: white;
}
main .container img {
  width: 100%;
}

.left {
  background-color: white;
  border-right: 1px solid #a4a4bc;
  flex: 1;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: grid;
  place-content: center;
}

@media (max-width: 767px) {
  .left {
    display: none;
  }
}

.right {
  flex: 1;
  padding: 2.5rem;
}
.right form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right form label {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
}
.right form small {
  color: #EA4C3C;
  text-align: center;
  display: block;
}
.right form svg {
  color: #747399;
}
.right h2, .forgot h3 {
  color: #393A62;
  text-align: center;
}
.button {
  position: fixed;
  top: 2rem;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
}
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 .8rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  display: none;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  transform: translate(1rem, 0);
}
button:hover .button-text {
  display: block;
  color: #fff;
}

form h5 {
        text-align: center;
        font-weight: 400;
        text-decoration: underline;
        color: #DBA09E;
    }

input {
      font-family: 'Poppins', sans-serif !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      color: #747399 !important;
}

.alert svg {
  color: #EA4C3C !important;
}

.forgot {
  width: 450px;
  background: white;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  position: relative;

}

.forgot input {
  padding: .7rem;
  border-color: #9292AE;
}

.backToLogin {
  position: absolute !important;
  bottom: 0.2rem;
  left: .7rem;
}