.calendar table {
  table-layout: fixed;
  width: 100%;
  column-span:all;
}

body {
  overflow: visible;
}
.calendar td,.calendar th {
  width: 100%;
  text-align: center;
  border-left: 0px solid;
  border-right: 0px solid;
  white-space: nowrap;
}

.example {
  width: 20px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  margin: 4px;
}

.inptd {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

#total {
  width: 30px;
  background-color: bisque;
  text-align: center;
  height: 25px;
  cursor: not-allowed;
}

.selOptions {
  width: 15rem;
  margin: auto;
  margin-bottom: 30px;
  cursor: pointer;
}


.notAllowed {
  background-color: rgb(179, 174, 174);
  color: black;
  width: 20px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  margin: 4px;
  cursor: not-allowed;
}

.fullname {
  padding: 50px 50px 0px 50px;
}

.name {
  font-size: 18px;
  font-weight: 700;
}

.dateVal {
  position: absolute;
  left: 1000px;
  padding-bottom: 30px;
}

.month {
  font-size: 18px;
  font-weight: bold;
}

.btmnonth {
  font-size: 16px;
  width: 150px;
  color: black;
  font-weight: 700;
  background-color: rgb(211, 226, 226);
  cursor: not-allowed;
}

.projects {
  padding: 10px 30px 15px 15px;
}
.prj {
  font-size: 18px;
  font-weight: 600;
}

.tableInser {
  width: 95%;
  padding-left: 40px;
}
.confirmation {
  text-align: center;
}


.Toastify__toast-container {
  width: 450px;
}

.card{
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 35rem;
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.halfDay{
  background: linear-gradient(45deg, #bbddeb 0% 50%, rgba(238,238,238,0) 40% 100%);
}

.noWorkDay{
  background: #F1F1FB;
  margin-bottom: auto;
}

.emptyDay{
  background: rgba(180,180,180,0.03);
}

.wholeDay{
  background: #bbddeb;
}

.day{
  color: black;
  height: 2.5rem;
  border: 0;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.calendarElement{
  display: flex;
  max-width: 2.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
}

.calendarElementNoWorkDay{
  display: flex;
  max-width: 2.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
}

.calendar td{
  vertical-align: top;
}



.dayValue {
  margin: 0.5rem auto;
  border-radius: 15%;
  padding: 0.3rem;
  height: 1.5rem;
  max-width: 1.5rem;
  width: 80%;
  border: 1px solid rgba(180,180,180,0.03);
  background-color: rgba(180,180,180,0.05);
}

.flexContainer{
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.date{
  padding-bottom: 1rem;
}

.calendarButton{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.calendarDay{
  cursor: pointer;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.calendarDay:hover{
  background-color: rgba(180,180,180,0.08);
}

.save:hover{
  background-color:#DBA09E;
}

@media screen and (max-width: 550px) {
.weekend {
  display: none;
}
}

.calendarHeader{
  text-align: center;
  margin: auto;
}
.monthCalendar{
  font-weight: bold;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

.calendarFooter{
  font-weight: bold;
  padding-top: 0.5rem;
  text-align: center;
  margin: auto;
}

