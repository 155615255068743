#containerPdf{
    margin: auto;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    margin-bottom: 3rem;
}


.invoiceContainer{
    margin: auto;
    margin-top: 1rem;
}

.logoInvoice{
    text-align : center;
}

.imgLogo{
    margin: auto;
    width: 10rem;
    margin-top: 2rem;
}

.containerInfo{
    margin: auto;
    width: 80%;
    display: flex;
    font-weight: 500;
}

.omdata{
    flex-grow: 1;
    width: 50%;
    background-color: #dbf2f9;
    color: #166a83;
    border-radius: 0.3rem 0 0 0.3rem;
}

.invoice{
    flex-grow: 1;
    width: 50%;
    background-color: #166a83;
    color: #ffffff;
    border-radius: 0 0.3rem 0.3rem 0;
}
 
.containerInfo h2{
    text-align: center;
    margin-bottom: 0.7rem;
    font-size: 1.3rem;
    margin-top: 1rem;
}
.text{
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.2rem;
}

.invoiceInfo{
    margin: auto;
    margin-top: 1rem;
    display: flex;
    font-weight: 500;
    width: 80%;
}

.invoiceTo{
    flex-grow: 2;
    padding-left: 1rem;
}

.invoiceDestination{
    flex-grow: 2;
    padding-left: 1rem;
}

.bold{
    font-weight: bold;
}

.table{
    margin: auto;
    width: 80%;
    margin-top: 2rem;
    border-collapse: collapse;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid #3eabcb;
}

.table thead tr th{
    color: #fff;
    font-weight: 100;
    margin: 0;
}

.table thead tr{
    background-color: #166a83;
}

.table th{
    text-align: left;
}

tr{
    height: 1.7rem;
}

.description{
    width: 45%;
    padding-left: 2rem;
}

.timeDay{
    width: 25%;
}

.tjm{
    width: 15%;
}

.price{
    width: 15%;
}

.table tbody tr:nth-child(2n) td {
    background-color: #dbf2f9;
  }

  .table tbody tr:nth-child(2n+1) td {
    background-color: #fff;
  }

  .table tbody tr td:nth-child(1){
      padding-left: 2rem;
  }

.infoPrice{
    width: 50%;
    margin: auto;
    text-align: end;
}

.tablePrice{
    width: 100%;
    border-spacing: 2rem 0.2rem;
}

.tablePrice td{
    width: 50%;
    vertical-align: middle;
}

.priceNumber{
    margin-right: 2rem;
    border-bottom: 2px solid #75c3da;
}

.spanInvoice{
    width: 80%;
    margin: auto;
    margin-top: 1rem;
}

.thankfulMessage{
    color: #166a83;
}

.infoIban{
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
}

.infoIban table tbody tr td:nth-child(2){
    border: 1px solid black;
    width: 18rem;
}

.generalCondition{
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 0.65rem;
    margin-top: 1rem;
}

#btnPrint{
    padding: 0.5rem 1.2rem;
    background-color: rgb(198, 198, 238);
    color: rgb(114, 112, 112);
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 550;
    cursor: pointer;
}

#btnPrint:hover{
    transition: all 0.2s;
    background-color: rgb(219, 219, 240);
}

.mailLink{
    text-decoration: none;
    color: inherit;
}